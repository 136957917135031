import React, { useEffect, useState } from 'react';
import './index.scss';
import { useHistory, useParams } from "react-router-dom";
import { Menu } from 'semantic-ui-react'
import axios from 'axios';
import { Link } from 'react-router-dom';

function MainMenu({ pageName }) {
    const { company } = useParams();
    let [companyName, setCompanyName] = useState("");

    useEffect(() => {
        if(!company || company === "undefined")
        {
            return;
        }

        let hasCompaniesCache = sessionStorage.getItem("companies") !== null;
        let hasCompanyInCache = false;
        let companyCache = {};

        if(hasCompaniesCache)
        {
            companyCache = JSON.parse(sessionStorage.getItem("companies"));
            hasCompanyInCache = Object.keys(companyCache).includes(company);
        }

        if(!hasCompanyInCache)
        {
            let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/company/${company}`;
            axios.get(url).then((response) => {
                setCompanyName(response.data.companyName);
                companyCache[company] = response.data.companyName;
                sessionStorage.setItem("companies", JSON.stringify(companyCache));
            });
        }
        else
        {
            setCompanyName(companyCache[company]);
        }
    }, [company])

    const linkClicked = (e) => {
        if(!company)
            e.preventDefault();
    };

    const designUrl = () => {
        if(!company)
            return "";
        else
            return `/${company}`;
    };

    const eventsUrl = () => {
        if(!company)
            return "";
        else
            return `/${company}/Events`;
    };

    const scenesUrl = () => {
        if(!company)
            return "";
        else
            return `/${company}/Scenes`;
    };

    const assetsUrl = () => {
        if(!company)
            return "";
        else
            return `/${company}/Assets`;
    };

    return <Menu color={"blue"}>
        <Menu.Item className={"company-name"} header>{companyName}</Menu.Item>
        <Link onClick={linkClicked} to={designUrl()}><Menu.Item active={pageName === "design"} link name="Design" /></Link>
        <Link onClick={linkClicked} to={eventsUrl()}><Menu.Item active={pageName === "events"} link disabled={(company) ? false : true} name="Events" /></Link>
        <Link onClick={linkClicked} to={scenesUrl()}><Menu.Item active={pageName === "scenes"} link disabled={(company) ? false : true} name="Scenes" /></Link>
        <Link onClick={linkClicked} to={assetsUrl()}><Menu.Item active={pageName === "assets"} link disabled={(company) ? false : true} name="Assets" /></Link>
        <Menu.Menu position='right'><Menu.Item name='examples' active={false} /><Menu.Item name='logout' active={false} /></Menu.Menu>
    </Menu>;
}

export default MainMenu;