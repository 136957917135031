import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Message, Container, Tab } from 'semantic-ui-react'
import MainMenu from '../menu';
import AssetsTable from './table';
import SharedAssetsTable from './table-shared';
import axios from 'axios';
import './index.scss';
import { Subject } from 'rxjs';

function Assets() {
    let { company } = useParams();
    let [assets, setAssets] = useState([]);
    let subject = new Subject();

    useEffect(() => {
        getAssets();
    }, [company]);

    useEffect(() => {
        subject.asObservable().subscribe((message: any) => {
            if(message.name === "assetAdded")
            {
                getAssets();
            }
            else if(message.name === "assetDeleted")
            {
                getAssets();
            }
        });
    }, [subject]);

    const getAssets = () => {
        let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/assets/company/${company}`;
        axios.get(url).then((response) => {
            setAssets(response.data);
        });
    };

    const companyAssets = () => {
        return assets.filter((a) => { return a.companyUUID === company; });
    };

    const sharedAssets = () => {
        return assets.filter((a) => { return a.companyUUID !== company; });
    };

    const panes = [
        {
            menuItem: 'Assets', render: () => <Tab.Pane>
                {/* <Header as={"h2"} dividing>Assets</Header> */}
                <AssetsTable subject={subject} assets={companyAssets()} />
            </Tab.Pane>
        },
        {
            menuItem: 'Shared Assets', render: () => <Tab.Pane>
                {/* <Header as={"h2"} dividing>Shared Assets</Header> */}
                <Message><Message.Header>What are Shared Assets</Message.Header>
    <p>
      These assets are provided courtesy of Fandangle and Digital Motion. They cannot be modified or edited in any way, and are essential for the function of the application.
    </p></Message>
                <SharedAssetsTable assets={sharedAssets()} />
            </Tab.Pane>
        },
    ]

    return <div>
        <MainMenu pageName="assets" />
        <Container fluid style={{ width: '95%', margin: '0px' }}>
            
            <Tab panes={panes} />
        </Container>
    </div>;
}

export default Assets;