import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import axios from 'axios';
import './index.scss';
import { Message, Header } from 'semantic-ui-react';
import Select from 'react-select';

function Scene({ visible, subject}) {
    const [scenes, setScenes] = useState([]);
    const location = useLocation();
    const history = useHistory();
    
    useEffect(()=>{
        subject.subscribe((message: any) => {
            if(message.name === "eventChanged")
            {
                loadScenes(message.data.eventUUID);
            }
        });
    }, [subject]);

    const loadScenes = (eventUUID) => {
        let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/scenes/event/${eventUUID}`;
        axios.get(url).then((response) => {
            setScenes(response.data);
        });
    };

    
    if(visible === false)
    {
        return <React.Fragment></React.Fragment>;
    }

    const getOptions = () => {
        let options = [ { label: "Select a Scene", value: "" } ];
        options = options.concat(scenes.map(e => { return { value: e.sceneUUID, label: e.sceneName }; }));
        return options;
    };

    const getValue = () => {
        let params = queryString.parse(location.search);
        let v = getOptions().filter(o => { return o.value === params.scene; })[0];
        if(!v)
            return getOptions()[0];

        return v;
    }

    const onChange = (e) => {
        const currentLocation = Object.assign({}, location);
        let params = queryString.parse(location.search);
        params.scene = e.value;

        if(!e.value)
        {
            delete params.scene;
        }

        currentLocation.search = queryString.stringify(params);
        history.push(currentLocation);

        subject.next({name:"sceneChanged", data: { sceneUUID: e.value }});
    };

    if (scenes.length < 1) 
    { 
        return <React.Fragment><Header dividing as='h4'>Scene</Header><Message info size="mini">There are no scenes available</Message></React.Fragment>;
    }

    return <React.Fragment>
        <Header dividing as='h4'>Scene</Header>
        <Select defaultValue={getOptions()[0]} value={getValue()} onChange={onChange} options={getOptions()} />
    </React.Fragment>;
}

export default Scene;