import React, { useState } from 'react';
import { Table, Message, Button, Confirm } from 'semantic-ui-react';
import { Link, useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import AddEventModal from './add-event-modal';
import EditEventModal from './edit-event-modal';

//Jan 15, 2020 3:43pm
function EventsTable({ subject, events }) {
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [eventUUIDToDelete, setEventUUIDToDelete] = useState(undefined);
    const { company } = useParams();
    const history = useHistory();
    const cancelDelete = () => {
        setConfirmDeleteOpen(false);
    };

    const confirmDelete = (e) => {
        deleteEvent();
    };

    const deleteClick = (e) => {
        let eventUUID = (e.target as HTMLElement).getAttribute("data-eventuuid");
        setEventUUIDToDelete(eventUUID);
        setConfirmDeleteOpen(true);
    };

    const deleteEvent = () =>{
        let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/events/${eventUUIDToDelete}`;
        
        axios.delete(url)
            .then((response) => {
                subject.next({ name: "eventDeleted", data: { eventUUID: eventUUIDToDelete }})
                setConfirmDeleteOpen(false);
            })
            .catch((err)=>{
                alert("something went wrong");
            });
    };

    const manageScenesClick = () => {
    }

    const row = (_event, indx) => {
        return <Table.Row key={indx}>
                <Table.Cell>{_event.eventName}</Table.Cell>
                <Table.Cell>{moment(_event.createdDate).format("MMM Do YYYY h:mm:ss a")}</Table.Cell>
                <Table.Cell textAlign="center"><Link to={`/${company}/${_event.eventUUID}/Scenes`}><Button content="Manage Scenes" size={"mini"} /></Link></Table.Cell>
                <Table.Cell textAlign="center">
                    <EditEventModal subject={subject} eventToEdit={_event} />
                    <Confirm dimmer={"blurring"} size='mini' header={`Delete Event`} content={`Are you sure you want to delete this event?`} cancelButton="Cancel" confirmButton="Yes, Delete" trigger={<Button data-eventuuid={_event.eventUUID} icon="delete" content="Delete" onClick={deleteClick} size={"mini"} negative />} open={confirmDeleteOpen} onCancel={cancelDelete} onConfirm={confirmDelete} />
                </Table.Cell>
            </Table.Row>;
    }

    const rows = () => {
        return (events.map((evnt, indx) => {
            return row(evnt, indx);
        }));
    };

    const empty = () => {
        return <Table.Row>
            <Table.Cell colSpan={4}><Message style={{ textAlign: "center" }} info>There are no events</Message></Table.Cell>
        </Table.Row>;
    };

    return <Table celled compact>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Event Name</Table.HeaderCell>
                <Table.HeaderCell>Created Date</Table.HeaderCell>
                <Table.HeaderCell>Scenes</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {(events && events.length > 0) ? rows() : empty()}
        </Table.Body>
        <Table.Footer>
            <Table.Row>
                <Table.HeaderCell colSpan='4'>
                    <Button onClick={() => { history.goBack(); }} size="small" icon="arrow left" basic color="grey" content="Back" />
                    <AddEventModal subject={subject} />
                </Table.HeaderCell>
            </Table.Row>
        </Table.Footer>
    </Table>;
}

export default EventsTable;