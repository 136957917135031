import React, { useState } from 'react';
import { Table, Message, Button, Image, Confirm } from 'semantic-ui-react';
import moment from 'moment';
import axios from 'axios';
import AddAssetModal from './add-asset-modal';
import filesize from 'filesize';
import { useHistory, Link, Redirect } from 'react-router-dom';

//Jan 15, 2020 3:43pm
function AssetsTable({ subject, assets }) {
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [assetUUIDToDelete, setAssetUUIDToDelete] = useState(undefined);

    const history = useHistory();
    const rows = () => {
        return (assets.map((a) => {
            return <Table.Row key={a.assetUUID}>
                <Table.Cell textAlign="center"><Image rounded src={a.url} size='mini'/></Table.Cell>
                <Table.Cell><a href={a.url}>{a.filename}</a></Table.Cell>
                <Table.Cell>{filesize(a.size)}</Table.Cell>
                <Table.Cell>{a.originalWidth || a.width} x {a.originalHeight || a.height}</Table.Cell>
                <Table.Cell>{moment(a.createdDate).format("MMM Do YYYY h:mm:ss a")}</Table.Cell>
                <Table.Cell textAlign="center">
                    <Confirm dimmer={"blurring"} size='mini' header={`Delete Asset`} content={`Are you sure you want to delete this asset?`} cancelButton="Cancel" confirmButton="Yes, Delete" trigger={<Button data-assetuuid={a.assetUUID} icon="delete" content="Delete" onClick={deleteClick} size={"mini"} negative />} open={confirmDeleteOpen} onCancel={cancelDelete} onConfirm={confirmDelete} />
                </Table.Cell>
            </Table.Row>;
        }));
    };

    const cancelDelete = () => {
        setConfirmDeleteOpen(false);
    };
    const confirmDelete = () => {
        deleteAsset();
    };

    const deleteClick = (e) => {
        let assetuuid = (e.target as HTMLElement).getAttribute("data-assetuuid");
        setAssetUUIDToDelete(assetuuid);
        setConfirmDeleteOpen(true);
    };

    const deleteAsset = () =>{
        let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/assets/${assetUUIDToDelete}`;
        
        axios.delete(url)
            .then((response) => {
                subject.next({ name: "assetDeleted", data: { assetUUID: assetUUIDToDelete }})
                setConfirmDeleteOpen(false);
            })
            .catch((err)=>{
                alert("something went wrong");
            });
    };

    const empty = () => {
        return <Table.Row>
            <Table.Cell colSpan={6}>
                <Message style={{ textAlign: "center" }} info>There are no assets</Message>
            </Table.Cell>
        </Table.Row>;
    };

    return <Table celled compact>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Preview</Table.HeaderCell>
                <Table.HeaderCell>File Name</Table.HeaderCell>
                <Table.HeaderCell>File Size</Table.HeaderCell>
                <Table.HeaderCell>Dimensions</Table.HeaderCell>
                <Table.HeaderCell>Created Date</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {(assets && assets.length > 0) ? rows() : empty()}
        </Table.Body>
        <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan='6'>
        <Button onClick={() => { history.goBack(); }} size="small" icon="arrow left" basic color="grey" content="Back" />
            <AddAssetModal subject={subject} />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
    </Table>;
}

export default AssetsTable;