import React, { useEffect, useState } from 'react';
import { Segment, Container, Header } from 'semantic-ui-react'
import MainMenu from '../menu';
import ScenesTable from './table';
import { useParams, useLocation, useHistory } from "react-router-dom";
import axios from 'axios';
import queryString from 'query-string';
import './index.scss';
import Select from 'react-select';
import { Subject } from 'rxjs';

function Scenes() {
    let { company, event } = useParams();
    let history = useHistory();
    let location = useLocation();
    let [scenes, setScenes] = useState([]);
    let [events, setEvents] = useState([]);
    let [selectedEvent, setSelectedEvent] = useState(event);
    const subject = new Subject();

    useEffect(() => {
        getScenes(event);
    }, [company]);

    useEffect(() => {
        subject.asObservable().subscribe((message: any) => {
            if(message.name === "sceneAdded")
            {
                getScenes(selectedEvent);
            }
            else if(message.name === "sceneDeleted")
            {
                getScenes(selectedEvent);
            }
            else if(message.name === "sceneUpdated")
            {
                getScenes(selectedEvent);
            }
        });
    }, [subject]);

    useEffect(() => {
        let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/events/company/${company}`;
        axios.get(url).then((response) => {
            if (response.data && response.data.length > 0) {
                
                setEvents(response.data);
            }
        });
    }, [company]);

    const getScenes = (eventUUID=null) => {
        
        let eventuuid = eventUUID || event;

        let hasEvent = (eventUUID !== null && eventUUID !== undefined);

        let allScenesForCompanyUrl = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/scenes/company/${company}`;
        let scenesForEvent = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/scenes/event/${eventuuid}`;

        let url = (hasEvent) ? scenesForEvent : allScenesForCompanyUrl;

        axios.get(url).then((response) => {
            setScenes(response.data);
        });
    }

    const getEventsOptions = () => {
        let options = [ { label: "All Events", value: null } ];
        options = options.concat(events.map(e => { return { value: e.eventUUID, label: e.eventName }; }));
        return options;
    };

    const getEventsSelectValue = () => {
        let opts = getEventsOptions();
        
        if((event && selectedEvent) && event === selectedEvent)
        {
            return opts.filter(e => { return e.value === event; })[0];
        }
        else if(selectedEvent)
        {
            return opts.filter(e => { return e.value === selectedEvent; })[0];
        }
        else
        {
            return opts[0];
        }
    };

    const eventChanged = (e) => {
        if(event)
        {
            const currentLocation = Object.assign({}, location);
            let parts = currentLocation.pathname.split("/");
            parts[2] = e.value;
            let newPathname = parts.join("/");
            currentLocation.pathname = newPathname;
            history.push(currentLocation);
        }
        setSelectedEvent(e.value)
        getScenes(e.value);
    }

    const getEvent = () => {
        let eventUUID = selectedEvent || event;
        let e = events.filter(e => { return e.eventUUID === eventUUID; })[0];
        return e;
    }

    return <div>
        <MainMenu pageName="scenes" />
        <Container fluid style={{ width: '95%', margin: '0px' }}>
            <Segment>
                <Header as={"h2"} dividing>Scenes</Header>
                <h4 style={{display: "inline-block", marginRight:"8px", marginTop:"0px"}}>Event: </h4>
                <div style={{display: "inline-block", width: "35%"}}>
                    <Select onChange={eventChanged} value={getEventsSelectValue()} options={getEventsOptions()} />
                </div>
                <ScenesTable subject={subject} scenes={scenes} event={getEvent()} />
            </Segment>
        </Container>
    </div>;
}

export default Scenes;