import React from 'react';
import { Table, Message, Image } from 'semantic-ui-react';
import moment from 'moment';
import filesize from 'filesize';

function SharedAssetsTable({ assets }) {

    const rows = () => {
        return (assets.map((a) => {
            return <Table.Row key={a.assetUUID}>
                <Table.Cell textAlign="center"><Image rounded src={a.url} size='mini'/></Table.Cell>
                <Table.Cell>{a.filename}</Table.Cell>
                <Table.Cell>{filesize(a.size)}</Table.Cell>
                <Table.Cell>{a.originalWidth || a.width} x {a.originalHeight || a.height}</Table.Cell>
                <Table.Cell>{moment(a.createdDate).format("MMM Do YYYY h:mm:ss a")}</Table.Cell>
            </Table.Row>;
        }));
    };

    const empty = () => {
        return <Table.Row>
            <Table.Cell colSpan={5}>
                <Message style={{ textAlign: "center" }} info>There are no assets</Message>
            </Table.Cell>
        </Table.Row>;
    };

    return <Table celled compact>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Preview</Table.HeaderCell>
                <Table.HeaderCell>File Name</Table.HeaderCell>
                <Table.HeaderCell>File Size</Table.HeaderCell>
                <Table.HeaderCell>Dimensions</Table.HeaderCell>
                <Table.HeaderCell>Created Date</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {(assets && assets.length > 0) ? rows() : empty()}
        </Table.Body>
        <Table.Footer>
    </Table.Footer>
    </Table>;
}

export default SharedAssetsTable;