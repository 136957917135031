import React from 'react';
import './index.scss';
import { List, Header, Message } from 'semantic-ui-react';
import SceneAssetModal from './scene-assets-modal';

function SceneAssets({ visible, subject, assets }) {
    if(visible === false)
    {
        return <React.Fragment></React.Fragment>;
    }
    
    // const setAsBackGround = (e, _asset) => {
    //     subject.next({ name: `setSceneAssetAsBg`, data: { asset: _asset, selected: !_asset.isBackground } });
    // }

    const renderAssetLineItems = () => {
        return (assets.map((a: any, indx) => {
            return <List.Item key={indx}>
                {/* <List.Icon title={"Set as background"} className={"clickable"} size="large" color="grey" name={(a.isBackground) ? 'check square outline' : 'square outline'} onClick={(event) => { setAsBackGround(event, a);  }} /> */}
                {/* <List.Icon title={"Options"} name={"ellipsis vertical"} className={"clickable"} size="large" color="grey" /> */}
                <List.Icon title={"Remove from scene"} className={"clickable"} color="grey" name='delete' size={"large"} onClick={(event) => { subject.next({ name: `removeAssetFromScene`, data: { asset: a } }); }} />
                {/* <List.Content className={"filename"}><a>{a.filename}</a></List.Content> */}
                <SceneAssetModal subject={ subject } asset={a} />
            </List.Item>;
        }));
    }
    const header = (<Header dividing as="h4">Current Scene</Header>);

    if (assets && assets.length > 0) {
        return <React.Fragment>
        {header}
        <div style={{ marginBottom: '5px' }}>
            <List>
                {renderAssetLineItems()}
            </List>
        </div></React.Fragment>;
    }

    return <React.Fragment>{header}<Message hidden={assets && assets.length > 0} info size='mini'>There are no assets in this scene.</Message></React.Fragment>;
}

export default SceneAssets;