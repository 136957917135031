import React, { useState } from 'react';
import './index.scss';
import axios from 'axios';
import { Button, Icon, Modal, Container, Form } from 'semantic-ui-react'
import { useParams } from 'react-router-dom';

function AddAssetModal({ subject }) {
    let [open, setOpen] = useState(false);
    let {company} = useParams();
    let [assetsToUpload, setAssetsToUpload] = useState([]);

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleSubmit = () => {
        saveAssets();
    };

    const saveAssets = () => {
        let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/assets/${company}`;

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };

        const formData = new FormData();

        assetsToUpload.slice(0, 5).map((a) => {
            formData.append('files', a);
        });

        axios.post(url, formData, config)
            .then((response) => {
                subject.next({ name: "assetAdded", data: { }})
                setOpen(false);
            })
            .catch((err)=>{
                alert("something went wrong: " + err);
            });
    };

    const uploadFilesChanged = (e) => {
        setAssetsToUpload(Array.from(e.target.files));
    };

    return <Modal open={open}
        trigger={<Button
            floated='right'
            icon
            labelPosition='left'
            primary
            size='small'
            onClick={(e) => { setOpen(true); }}
        >
            <Icon name='add square' /> Add Asset(s)
      </Button>}
        centered={false}>
        <Modal.Header>Add an Asset</Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <Container fluid>
                    <Form>
                        <Form.Field>
                            <label>Upload File(s)</label>
                            <input onChange={uploadFilesChanged} type="file" multiple accept=".png,.jpg,.jpeg,bmp" />
                        </Form.Field>
                    </Form>
                </Container>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button secondary onClick={handleClose} inverted>
                {/* <Icon name='checkmark' />*/} Cancel
            </Button>
            <Button primary onClick={handleSubmit}>
                {/* <Icon name='checkmark' />*/} Submit
          </Button>
        </Modal.Actions>
    </Modal>
}

export default AddAssetModal;