import React, { useState, useEffect } from 'react';
import './index.scss';
import { Button, Modal, Container, Divider, Message, Form, Icon } from 'semantic-ui-react'
import Select from 'react-select';
import { Link, useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
const uuidv4 = require('uuid/v4');

function SelectBackgroundModal({ subject, assets }) {
    let [open, setOpen] = useState(false);
    let [assetUUID, setAssetUUID] = useState();
    const { company } = useParams();
    const location = useLocation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        setBackground();
    };

    const setBackground = () => {
        let asset = assets.filter(a => { return a.assetUUID === assetUUID; })[0];

        if (!asset.sceneId) {
            asset.sceneId = uuidv4();
        }

        subject.next({ name: "addAssetAsBgToScene", data: { asset: asset } });

        setOpen(false);
    };

    const backgroundSelected = (e) => {
        setAssetUUID(e.value);
    }

    useEffect(() => {
        subject.subscribe((message: any) => {
            if (message.name === "openBgModal") {

                setOpen(true);
            }
        });
    }, [subject]);

    return <Modal open={open}
        centered={false}>
        {/* <Modal.Header>Select a Background</Modal.Header> */}
        <Modal.Content>
            <Modal.Description>
                <Container fluid textAlign="center">
                    {/* <Divider horizontal>Or</Divider> */}
                    <Message
                        attached
                        header='Select a Background Image'
                    />
                    <Form className='attached fluid segment'>

                        <Form.Field>
                            <Select onChange={backgroundSelected} options={assets.map(a => { return { value: a.assetUUID, label: a.filename } })} />
                        </Form.Field>
                    </Form>
                    <Message attached='bottom' warning>
                        <Icon name='help' />
                        Need another asset?&nbsp;<Link to={`/${company}/Assets`}>Mangage Assets Here</Link>&nbsp;instead.
    </Message>

                </Container>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button secondary onClick={handleClose} inverted>
                {/* <Icon name='checkmark' />*/} Cancel
            </Button>
            <Button primary onClick={handleSubmit}>
                {/* <Icon name='checkmark' />*/} Confirm
          </Button>
        </Modal.Actions>
    </Modal>
}

export default SelectBackgroundModal;