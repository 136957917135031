import React, { useState } from 'react';
import './index.scss';
import { Button, Header, Form, Container, List, Image, Modal, Divider } from 'semantic-ui-react'
import filesize from 'filesize';

function AllAssetsModal({ subject, asset }) {
    let [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddToScene = () => {
        subject.next({ name: "addAssetToScene", data: { asset: asset } });
        setOpen(false);
    }

    return <Modal open={open}
        trigger={<List.Content onClick={(e)=>{setOpen(true);}} className={"filename"}><a>{asset.filename.replace(asset.extension, "")}</a></List.Content>} centered={false}>
        <Modal.Header>Asset Options</Modal.Header>
        <Modal.Content image>
            <Image wrapped size='medium' src={asset.url} />
            <Modal.Description>
                <Header color={"grey"}>{asset.filename}</Header>
                <Divider />
                <Container fluid>
                    <Form>
                        <Form.Field>
                            <span>File Size: {filesize(asset.size)}</span>
                        </Form.Field>
                        <Form.Field>
                            <span>Dimensions: {asset.originalWidth || asset.width} x {asset.originalHeight || asset.height}</span>
                        </Form.Field>
                    </Form>
                </Container>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={handleAddToScene} inverted>
            {/* <Icon name='checkmark' />*/} Add to Scene 
          </Button>
          <Button secondary onClick={handleClose} inverted>
            {/* <Icon name='checkmark' />*/} Cancel 
          </Button>
        </Modal.Actions>
    </Modal>
}

export default AllAssetsModal;