import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Segment, Container, Header } from 'semantic-ui-react'
import MainMenu from '../menu';
import EventsTable from './table';
import axios from 'axios';
import './index.scss';
import { Subject } from 'rxjs';

function Events() {
    let { company } = useParams();
    let [events, setEvents] = useState([]);
    let subject = new Subject();

    useEffect(() => {
        getEvents();
    }, [company]);

    useEffect(() => {
        subject.asObservable().subscribe((message: any) => {
            if(message.name === "eventAdded")
            {
                getEvents();
            }
            else if(message.name === "eventDeleted")
            {
                getEvents();
            }
            else if(message.name === "eventUpdated")
            {
                getEvents();
            }
        });
    }, [subject]);

    const getEvents = () => {
        let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/events/company/${company}`;
        axios.get(url).then((response) => {
            setEvents(response.data);
        });
    };

    return <div>
        <MainMenu pageName="events" />
        <Container fluid style={{ width: '95%', margin: '0px' }}>
            <Segment>
                <Header as={"h2"} dividing>Events</Header>
                <EventsTable subject={subject} events={events} />
            </Segment>
        </Container>
    </div>;
}

export default Events;