import Fraction from 'fraction.js';

const largest = (containerWidth, containerHeight, padPercent, x=undefined, y=undefined) => {
  let _x = x || 16;
  let _y = y || 9;

  let r = new Fraction(_x / _y);

  const reducer = (numerator, denominator) => {
    let _gcd = function gcd(a, b) {
      return b ? gcd(b, a % b) : a;
    };
    let gcd = _gcd(numerator, denominator);
    return [numerator / gcd, denominator / gcd];
  };

  let padYPercent = padPercent;
  let padXPercent = padPercent;
  let rx = r.n;
  let ry = r.d;
  
  let maxX = containerWidth * padXPercent;
  let maxY = containerHeight * padYPercent;
  
  let currentY = ry;
  let currentX = rx;

  while (currentX <= maxX) {
    if (currentY >= maxY) {
      break;
    }
    currentX += rx;
    currentY += ry;
  }

  if (currentX > maxX || currentY > maxY) {
    currentX = currentX - rx;
    currentY = currentY - ry;
  }

  let xScale = 1; 
  let yScale = 1; 

  if(x && y)
  {
    xScale = Math.min(1, currentX / _x);
    yScale = Math.min(1, currentY / _y);
  }

  if((!x && !y))
  {
    let a1 = containerWidth * containerHeight;
    let a2 = currentX * currentY;
    let s = Math.min(a1, a2) / Math.max(a1, a2);
    xScale = s;
    yScale = s;
  }
  
  let reduced = reducer(currentX, currentY);
  
  return {
    width: currentX,
    height: currentY,
    inputRatio: r.toFraction(false).replace("/", ":"),
    container: {
      width: containerWidth,
      height: containerHeight
    },
    inputDimensions:{
      width: x,
      height: y
    },
    aspectRatio: {
      decimal: currentX / currentY,
      x: reduced[0],
      y: reduced[1]
    },
    scale: { x: xScale, y: yScale }
  };
};

export { largest };
