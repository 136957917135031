import React from 'react';
import useImage from 'use-image';
import { Image } from 'react-konva';

function Background({ options, asset })
{
    let url = (asset) ? asset.url : "";

    const [image] = useImage(url);

    return <Image name={"background"} image={image} />;
}

export default Background;