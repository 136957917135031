import React, { useState, useEffect } from 'react';
import './index.scss';
import axios from 'axios';
import queryString from 'query-string';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Button, Modal, Form, Container } from 'semantic-ui-react'
import Select from 'react-select';

function AddSceneModal({ subject, inputEvent }) {
    let [open, setOpen] = useState(false);
    const history = useHistory();
    const { company } = useParams();
    const location = useLocation();

    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState();
    let [newSceneToAdd, setNewSceneToAdd] = useState({ eventUUID: (inputEvent) ? inputEvent.eventUUID : "", sceneName: "", description: "" });

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        saveScene();
    };

    const saveScene = () =>{
        let eventUUID = (inputEvent) ? inputEvent.eventUUID : selectedEvent;
        let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/scenes/${eventUUID}`;

        axios.post(url, newSceneToAdd)
            .then((response) => {
                subject.next({ name: "sceneAdded", data: { scene: newSceneToAdd }})
                setOpen(false);
            })
            .catch((err)=>{
                alert("something went wrong");
            });
    };

    useEffect(() => {
        let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/events/company/${company}`;

        axios.get(url).then((response) => {
            if (response.data && response.data.length > 0) {
                setEvents(response.data);
            }
        });
    }, [company]);

    useEffect(()=>{
        if(!inputEvent)
            return;

        let s = Object.assign({}, newSceneToAdd);
        s.eventUUID = inputEvent.eventUUID;
        setNewSceneToAdd(s);
        setSelectedEvent(inputEvent.eventUUID);
    }, [inputEvent]);

    const getOptions = () => {
        let options = [ { label: "Select an Event", value: null } ];
        options = options.concat(events.map(e => { return { value: e.eventUUID, label: e.eventName }; }));
        return options;
    };

    const getEventValue = () => {
        if(inputEvent)
        {
            return getOptions().filter(e => { return e.value === inputEvent.eventUUID})[0];
        }
        else if (selectedEvent)
        {
            return getOptions().filter(e => { return e.value === selectedEvent})[0];
        }
        
        return getOptions()[0];
    }

    const onEventChange = (e) => {
        let s = Object.assign({}, newSceneToAdd);
        s.eventUUID = e.value;
        setNewSceneToAdd(s);
        setSelectedEvent(e.value);
    };

    const onSceneNameChange = (e) => {
        let s = Object.assign({}, newSceneToAdd);
        s.sceneName = e.target.value;
        setNewSceneToAdd(s);
    };

    const onSceneDescriptionChange = (e) => {
        let s = Object.assign({}, newSceneToAdd);
        s.description = e.target.value;
        setNewSceneToAdd(s);
    };

    return <Modal open={open}
        trigger={<Button icon="add square"
            labelPosition='left'
            primary
            size='small' onClick={(e) => { setOpen(true); }} floated={"right"} content='Add a Scene' />}
        centered={false}>
        <Modal.Header>Add a Scene</Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <Container fluid>
                    <Form>
                        <Form.Field>
                            <label>Event</label>
                            <Select isDisabled={((inputEvent) ? true : false)} defaultValue={getOptions()[0]} value={getEventValue()} onChange={onEventChange} options={getOptions()} />
                        </Form.Field>
                        <Form.Field>
                            <label>Scene Name</label>
                            <input value={newSceneToAdd.sceneName} onChange={onSceneNameChange} placeholder='Scene Name' />
                        </Form.Field>
                        <Form.Field>
                            <label>Description</label>
                            <textarea value={newSceneToAdd.description} onChange={onSceneDescriptionChange} rows={3} placeholder='Description' />
                        </Form.Field>
                    </Form>
                </Container>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button secondary onClick={handleClose} inverted>
                    {/* <Icon name='checkmark' />*/} Cancel
            </Button>
            <Button primary onClick={handleSubmit}>
                {/* <Icon name='checkmark' />*/} Submit
          </Button>
        </Modal.Actions>
    </Modal>
}

export default AddSceneModal;