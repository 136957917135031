import React, { useState, useEffect } from 'react';
import './index.scss';
import axios from 'axios';
import { Button, Modal, Form, Container } from 'semantic-ui-react'
import Select from 'react-select';
import { useParams } from 'react-router-dom';

function EditSceneModal({ subject, scene, eventUUID }) {
    let [open, setOpen] = useState(false);
    let { company } = useParams();
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState();
    const [sceneToEdit, setSceneToEdit] = useState(scene);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        editScene();
    }

    const editScene = () =>{
        let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/scenes`;
        axios.put(url, sceneToEdit)
            .then((response) => {
                subject.next({ name: "sceneUpdated", data: { scene: sceneToEdit }})
                setOpen(false);
            })
            .catch((err)=>{
                alert("something went wrong");
            });
    };

    useEffect(() => {
        let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/events/company/${company}`;

        axios.get(url).then((response) => {
            if (response.data && response.data.length > 0) {
                setEvents(response.data);
            }
        });
    }, [company]);

    const getOptions = () => {
        let options = [ { label: "Select an Event", value: null } ];
        options = options.concat(events.map(e => { return { value: e.eventUUID, label: e.eventName }; }));
        return options;
    };

    const getEventValue = () => {
        if (selectedEvent)
        {
            return getOptions().filter(e => { return e.value === selectedEvent})[0];
        }
        else if(eventUUID)
        {
            return getOptions().filter(e => { return e.value === eventUUID})[0];
        }
        
        return getOptions()[0];
    }

    const onEventChange = (e) => {
        setSelectedEvent(e.value);
        let s = Object.assign({}, sceneToEdit); 
        s.eventUUID = e.value;
        setSceneToEdit(s);
    };

    const onSceneNameChange = (e) => {
        let s = Object.assign({}, sceneToEdit); 
        s.sceneName = e.target.value;
        setSceneToEdit(s);
    }

    const onSceneDescriptionChange = (e) => {
        let s = Object.assign({}, sceneToEdit); 
        s.description = e.target.value;
        setSceneToEdit(s);
    }

    return <Modal open={open}
        trigger={<Button icon="pencil" onClick={(e) => { setOpen(true); }} content="Edit" size={"mini"} primary />}
        centered={false}>
        <Modal.Header>Edit Scene</Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <Container fluid>
                    <Form>
                        <Form.Field>
                            <label>Event</label>
                            <Select defaultValue={getOptions()[0]} value={getEventValue()} onChange={onEventChange} options={getOptions()} />
                        </Form.Field>
                        <Form.Field>
                            <label>Scene Name</label>
                            <input onChange={onSceneNameChange} value={sceneToEdit.sceneName} placeholder='Scene Name' />
                        </Form.Field>
                        <Form.Field>
                            <label>Description</label>
                            <textarea onChange={onSceneDescriptionChange} value={sceneToEdit.description} rows={3} placeholder='Description' />
                        </Form.Field>
                    </Form>
                </Container>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button secondary onClick={handleClose} inverted>
                {/* <Icon name='checkmark' />*/} Cancel
            </Button>
            <Button primary onClick={handleSubmit}>
                {/* <Icon name='checkmark' />*/} Submit
          </Button>
        </Modal.Actions>
    </Modal>
}

export default EditSceneModal;