import React, { useState } from 'react';
import './index.scss';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Icon, Modal, Form, Container } from 'semantic-ui-react'

function AddEventModal({ subject }) {
    let [open, setOpen] = useState(false);
    let [eventName, setEventName] = useState("");

    const { company } = useParams();

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        saveEvent();
    };

    const saveEvent = () =>{
        let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/events/${company}`;
        let evnt = {
            EventName: eventName
        };
        axios.post(url, evnt)
            .then((response) => {
                subject.next({ name: "eventAdded", data: { event: evnt }})
                setOpen(false);
            })
            .catch((err)=>{
                alert("something went wrong");
            });
    };

    return <Modal open={open}
        trigger={<Button
            floated='right'
            icon
            labelPosition='left'
            primary
            size='small'
            onClick={(e)=>{setOpen(true);}}
        >
            <Icon name='add square' /> Add Event
      </Button>}
        centered={false}>
        <Modal.Header>Add an Event</Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <Container fluid>
                    <Form>
                        <Form.Field>
                            <label>Event Name</label>
                            <input value={eventName} onChange={(e) => { setEventName(e.target.value); }} placeholder='Event Name' />
                        </Form.Field>
                    </Form>
                </Container>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button secondary onClick={handleClose} inverted>
                    {/* <Icon name='checkmark' />*/} Cancel
            </Button>
            <Button primary onClick={handleSubmit}>
                {/* <Icon name='checkmark' />*/} Submit
          </Button>
        </Modal.Actions>
    </Modal>
}

export default AddEventModal;