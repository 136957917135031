import React, { useState, useEffect } from 'react';
import './index.scss';
import { Button, Header, Form, Divider, Container, List, Image, Modal, Checkbox } from 'semantic-ui-react'
import filesize from 'filesize';

function SceneAssetsModal({ subject, asset }) {
    let [open, setOpen] = useState(false);
    let [isBackground, setIsBackground] = useState(asset.isBackground);

    const handleClose = () => {
        setOpen(false);
    };

    const setAsBackgroundChanged = (asset) => {
        subject.next({ name: "setSceneAssetAsBg", data: { asset: asset, selected: !asset.isBackground } });
    }

    const setAsBackgroundClicked = (asset) => {
        setIsBackground(!isBackground);
    }

    useEffect(() => {
        subject.subscribe((message: any) => {
            if (message.name === "openSceneAssetModal") {
                if (message.data.asset.sceneId === asset.sceneId) {
                    setOpen(true);
                }
            }
        });
    }, [subject]);

    return <Modal open={open}
        trigger={<List.Content onClick={(e) => { setOpen(true); }} className={"filename"}><a>{asset.filename}</a></List.Content>} centered={false}>
        <Modal.Header>Asset Options</Modal.Header>
        <Modal.Content image>
            <Image wrapped size='medium' src={asset.url} />
            <Modal.Description>
                <Header color={"grey"}>{asset.filename}</Header>
                <Divider />
                <Container fluid>
                    <Form>
                        <Form.Field>
                            <span>File Size: {filesize(asset.size || 0)}</span>
                        </Form.Field>
                        <Form.Field>
                            <span>Dimensions: {asset.originalWidth || asset.width} x {asset.originalHeight || asset.height}</span>
                        </Form.Field>
                        <Form.Field>
                            <Checkbox onClick={setAsBackgroundClicked.bind(null, asset)} onChange={setAsBackgroundChanged.bind(null, asset)} checked={isBackground} label='Set as Background' />
                        </Form.Field>
                    </Form>
                </Container>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button color='blue' onClick={handleClose} inverted>
                {/* <Icon name='checkmark' />*/} Done
          </Button>

        </Modal.Actions>
    </Modal>
}

export default SceneAssetsModal;