import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './index.scss';
import { Button, Modal, Container, Form } from 'semantic-ui-react'

function EditEventModal({ subject, eventToEdit }) {
    let [open, setOpen] = useState(false);
    let [newEventToEdit, setNewEventToEdit] = useState(eventToEdit);

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        editEvent();
    };

    const editEvent = () =>{
        let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/events`;
        axios.put(url, newEventToEdit)
            .then((response) => {
                subject.next({ name: "eventUpdated", data: { event: newEventToEdit }})
                setOpen(false);
            })
            .catch((err)=>{
                alert("something went wrong");
            });
    };

    return <Modal open={open}
        trigger={<Button icon="pencil" onClick={(e)=>{setOpen(true);}} content="Edit" size={"mini"} primary />}
        centered={false}>
        <Modal.Header>Edit Event</Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <Container fluid>
                    <Form>
                        <Form.Field>
                            <label>Event Name</label>
                            <input autoFocus value={newEventToEdit.eventName} onChange={(e) => { 
                                let x = Object.assign({}, newEventToEdit); 
                                x.eventName = e.target.value; 
                                setNewEventToEdit(x); 
                                }} placeholder='Event Name' />
                        </Form.Field>
                    </Form>
                </Container>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button secondary onClick={handleClose} inverted>
                    {/* <Icon name='checkmark' />*/} Cancel
            </Button>
            <Button primary onClick={handleSubmit}>
                {/* <Icon name='checkmark' />*/} Submit
          </Button>
        </Modal.Actions>
    </Modal>
}

export default EditEventModal;