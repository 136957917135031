import React from 'react';
import useImage from 'use-image';
import { Image, Transformer } from 'react-konva';

function Asset({ imageProps, isSelected, asset, onSelect, onChange, subject }) {
  const [image] = useImage(asset.url);
  const imgRef = React.useRef(null);
  const transformerRef = React.useRef(null);

  React.useEffect(() => {
    if (isSelected) {
      transformerRef.current.setNode(imgRef.current);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);
  if(asset.canRender === false)
  {
    return <React.Fragment></React.Fragment>
  }

  const assetDblClick = () => {
    subject.next({name:"openSceneAssetModal", data:{ asset: asset }})
  }

  const assetMouseDown = () => {
    document.body.style.cursor = "grabbing";
  }

  const assetMouseUp = () => {
    document.body.style.cursor = "default";
  } 

  return <React.Fragment>
    <Image x={asset.x} y={asset.y} width={asset.width} height={asset.height} onMouseDown={assetMouseDown} onMouseUp={assetMouseUp} onDblClick={assetDblClick} name={asset.filepath} ref={imgRef} {...imageProps} onClick={onSelect} draggable image={image} onTransformEnd={e => {
      const node = imgRef.current;
      onChange({
        ...imageProps,
        sceneId: asset.sceneId,
        x: asset.x,
        y: asset.y
      });
    }} onDragEnd={e => {
      const node = imgRef.current;
      onChange({
        ...imageProps,
        sceneId: asset.sceneId,
        x: e.target.x(),
        y: e.target.y(),
        width: asset.width,
        height: asset.height
      });
    }} />
    {isSelected && (<Transformer
      ref={transformerRef}
      keepRatio={true}
      rotateEnabled={false}
      enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
      boundBoxFunc={(oldBox, newBox) => {
        let resultBox = null;

        if (newBox.width < 5 || newBox.height < 5) {
          resultBox = oldBox;
        }

        resultBox = newBox;

        subject.next({ name:"assetResized", data: { asset: Object.assign({}, asset), prevBox: oldBox, box: resultBox }});

        return resultBox;
      }}
    />)}
  </React.Fragment>;
}

export default Asset;