import React from 'react';
import './index.scss';
import AllAssets from './all-assets';
import SceneAssets from './scene-assets';
import Events from './event';
import Scene from './scene';
import {Header } from 'semantic-ui-react';

function Options({subject, scene, assets, visibility}) {
    return <div>
        <Events visible={visibility.events} subject={subject} />
        <Scene visible={visibility.scenes} subject={subject} />
        <AllAssets visible={visibility.allAssets} subject={subject} assets={assets} />
        <SceneAssets visible={visibility.sceneAssets} subject={subject} assets={scene.assets} />
    </div>;
}

export default Options;