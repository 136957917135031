import React, { useState } from 'react';
import { Table, Message, Button, Icon, Confirm } from 'semantic-ui-react';
import { useHistory, useParams, Link } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';
import EditSceneModal from './edit-scene-modal';
import AddSceneModal from './add-scene-modal';

//Jan 15, 2020 3:43pm
function ScenesTable({ subject, scenes, event }) {
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const history = useHistory();
    const { company } = useParams();
    const [sceneUUIDToDelete, setSceneUUIDToDelete] = useState();

    const cancelDelete = () => {
        setConfirmDeleteOpen(false);
    };
    const confirmDelete = () => {
        deleteScene();
    };

    const deleteClick = (e) => {
        let sceneUUID = (e.target as HTMLElement).getAttribute("data-sceneuuid");
        setSceneUUIDToDelete(sceneUUID);
        setConfirmDeleteOpen(true);
    };

    const deleteScene = () => {
        let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/scenes/${sceneUUIDToDelete}`;
        
        axios.delete(url)
            .then((response) => {
                subject.next({ name: "sceneDeleted", data: { sceneUUID: sceneUUIDToDelete }})
                setConfirmDeleteOpen(false);
            })
            .catch((err)=>{
                alert("something went wrong");
            });
    };

    const rows = () => {
        return (scenes.map((s) => {
            return <Table.Row key={s.sceneUUID}>
                <Table.Cell>{s.sceneName}</Table.Cell>
                <Table.Cell>{s.description}</Table.Cell>
                <Table.Cell>{s.eventName}</Table.Cell>
                <Table.Cell>{moment(s.createdDate).format("MMM Do YYYY h:mm:ss a")}</Table.Cell>
                <Table.Cell textAlign="center">
                    <Link to={`/${company}?event=${s.eventUUID}&scene=${s.sceneUUID}`}><Button icon="code" content="Design" size={"mini"} basic /></Link>
                    <EditSceneModal subject={subject} scene={s} eventUUID={s.eventUUID} />
                    <Confirm dimmer={"blurring"} size='mini' header={`Delete Scene`} content={`Are you sure you want to delete this scene?`} cancelButton="Cancel" confirmButton="Yes, Delete" trigger={<Button data-sceneuuid={s.sceneUUID} icon="delete" content="Delete" onClick={deleteClick} size={"mini"} negative />} open={confirmDeleteOpen} onCancel={cancelDelete} onConfirm={confirmDelete} />
                </Table.Cell>
            </Table.Row>;
        }));
    };
    const empty = () => {
        return <Table.Row>
            <Table.Cell colSpan={5}><Message style={{ textAlign: "center" }} info>There are no scenes</Message></Table.Cell>
        </Table.Row>;
    };

    return <Table celled compact>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Scene Name</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Event Name</Table.HeaderCell>
                <Table.HeaderCell>Created Date</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {(scenes && scenes.length > 0) ? rows() : empty()}
        </Table.Body>
        <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan='5'>
            <Button onClick={() => { history.goBack(); }} size="small" icon="arrow left" basic color="grey" content="Back" />
            <AddSceneModal subject={subject} inputEvent={event} />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
    </Table>;
}

export default ScenesTable;