// import React, { useEffect } from 'react';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { Container } from "semantic-ui-react";
import Events from "./events";
import Assets from "./assets";
import Scenes from "./scenes";

// eslint-disable-next-line
const Main = ({}) => (
  <Container fluid style={{ margin: '0px' }}>
    <Router>
      <Route exact path="/" component={App} />
      <Route exact path="/:company" component={App} />
      <Route exact path="/:company/Events" component={Events} />
      <Route exact path="/:company/Assets" component={Assets} />
      <Route exact path="/:company/Scenes" component={Scenes} />
      <Route exact path="/:company/:event/Scenes" component={Scenes} />
    </Router>
  </Container>
);

const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css";
document.head.appendChild(styleLink);

ReactDOM.render(<Main></Main>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
