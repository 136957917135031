import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import { useParams, useHistory, useLocation } from "react-router-dom";
import './App.scss';
import Asset from './canvas-asset';
import queryString from 'query-string';
import { Subject } from 'rxjs';
import axios from 'axios';
import { Stage, Layer, Group, Rect, Text } from 'react-konva';
import Background from './canvas-background';
import Options from './options';
import MainMenu from './menu';
import { largest } from './largest';
import { debounce } from 'lodash';
import SelectBackgroundModal from './select-background-modal';
import Konva from 'konva';
const uuidv4 = require('uuid/v4');

function App(props: any) {
  const [l, setL] = useState({ width: 0, height: 0, scale: { x: 1, y: 1 } });
  const [bgLoc, setBgLoc] = useState({ x: 0, y: 0 });
  const [bgRectTextOffset, setBgRectTextOffset] = useState({ x: 0, y: 0 });
  const location = useLocation();
  const [scene, setScene] = useState({ assets: [], dirty: false });
  const [assets, setAssets] = useState([]);
  const [optionsVisibility, setOptionsVisibility] = useState({});
  const [selectedImageSceneId, selectImage] = React.useState(null);
  const subject = new Subject();
  const assetFrameWrapper = useRef(null);
  const bgInfoText = useRef(null);
  const stageRef = useRef(null);
  const size = useWindowSize();
  const bgRef = useRef(null);
  const layerRef = useRef(null);
  const debouncedSaveScene = useRef(debounce((q, l) => saveScene(q, l), 1000 )).current;
  const { company } = useParams();
  
  useEffect(()=>{
    if(scene.dirty === true)
    {
      setScene({ assets: scene.assets, dirty: false });
      debouncedSaveScene(scene.assets, queryString.parse(location.search));
    }
  }, [scene.assets, location.search]);

  const saveScene = (_assets, params) => {
    _assets = _assets.map(a => {
      return {
        SceneUUID: params.scene,
        AssetUUID: a.assetUUID,
        LocationX: parseInt(a.x),
        LocationY: parseInt(a.y),
        Width: parseInt(a.width),
        Height: parseInt(a.height),
        IsBackground: (a.isBackground === true) ? true : false,
      };
    });
    let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/scenes/${params.scene}/Assets`;
    axios.post(url, _assets)
      .then((response) => {
        
      })
      .catch((err)=>{
          alert("something went wrong: " + err);
      });
  };

  useLayoutEffect(() => {
    subject.subscribe((message: any) => {
      if (message.name === "addAssetToScene") {
        let _sceneAssets = addAssetToScene(message.data.asset);
        setScene({ assets: _sceneAssets, dirty: true });
      }
      else if (message.name === "addAssetAsBgToScene") {
        let _sceneAssets = addAssetToScene(message.data.asset);
        _sceneAssets = setAssetAsBg(message.data.asset, _sceneAssets);
        setScene({ assets: _sceneAssets, dirty: true });
      }
      else if (message.name === "removeAssetFromScene") {
        let _sceneAssets = Object.assign([], scene.assets).filter((a) => {
          return a.sceneId !== message.data.asset.sceneId;
        });
        setScene({ assets: _sceneAssets, dirty: true });
      }
      else if(message.name === "assetResized")
      {
        let _sceneAssets = Object.assign([], scene.assets).map((a) => {
          if(a.sceneId === message.data.asset.sceneId)
          {
            a.width = message.data.box.width;
            a.height = message.data.box.height;
          }
          return a;
        });
        setScene({ assets: _sceneAssets, dirty: true });
      }
      else if (message.name === "sceneChanged")
      {
        
      }
      else if (message.name === "loadAssetsForCompany") {
        loadAssets();
      }
      else if (message.name === "setSceneAssetAsBg") {
        let _sceneAssets = setAssetAsBg(message.data.asset, Object.assign([], scene.assets));
        setScene({ assets: _sceneAssets, dirty: true });
      }
    });
  }, [scene, subject]);

  const getBackgroundAsset = useCallback(() => {
    if (!scene.assets || scene.assets.length === 0) {
      return null;
    }

    let backgroundAssets = scene.assets.filter((a) => { return a.isBackground === true; });
    return (backgroundAssets && backgroundAssets.length > 0) ? backgroundAssets[0] : null;
  }, [scene.assets]);

  const addAssetToScene = (assetToAddToScene) => {
    let asset = Object.assign({}, assetToAddToScene);
    asset.x = 0;
    asset.y = 0; 
    asset.originalWidth = asset.width;
    asset.originalHeight = asset.height;
    asset.width = asset.width * l.scale.x;
    asset.height = asset.height * l.scale.y;

    if(!asset.sceneId)
    {
      asset.sceneId = uuidv4();
    }

    let _sceneAssets = Object.assign([], scene.assets);
    _sceneAssets.push(asset);

    return _sceneAssets;
  }

  const setAssetAsBg = (assetToSetAsBg, assets) =>{
    let sceneAssets = assets.map((a) => {
      if (assetToSetAsBg.sceneId === a.sceneId) {
        a.isBackground = !assetToSetAsBg.isBackground;
        a.canRender = !a.isBackground;
      }
      else {
        a.isBackground = false;
        a.canRender = true;
      }

      return a;
    });
    return sceneAssets;
  }

  const loadAssets = () => {
    let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/assets/company/${company}`;

    if (!company || company.length === 0)
      return;

    axios.get(url).then((response) => {
      setAssets(response.data);
    });
  };

  useEffect(()=>{
    let params = queryString.parse(location.search);
    let sceneUUID = params.scene;
    if(!sceneUUID || sceneUUID.length <= 0)
    {
      setScene({assets: [], dirty: false});
      return;
    }

    if (!company || company.length === 0)
    {
      setScene({assets: [], dirty: false});
      return;
    }

    let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/scenes/${sceneUUID}/assets`;

    let s = Object.assign({}, scene);

    s.dirty = false;
    axios.get(url).then((response) => {
      s.assets = response.data.map((sa) => { 
        let a = Object.assign({}, sa.asset);
        if(!a.scenedId)
          a.sceneId = uuidv4();

        a.width = sa.width;
        a.height = sa.height;
        a.x = sa.locationX;
        a.y = sa.locationY;
        a.isBackground = sa.isBackground;
        a.canRender = !a.isBackground;
        return a;
      });
      setScene(s);
    });
  }, [company, location.search]);

  React.useEffect(() => {
    let c = layerRef.current.getCanvas();
    let padding = 0.8;
    let w = assetFrameWrapper.current.clientWidth;
    let h = assetFrameWrapper.current.clientHeight;
    let bg = getBackgroundAsset();
    stageRef.current.width(w);
    stageRef.current.height(h);

    let result = null;

    if (!bg) {
      result = largest(c.width, c.height, padding);
    }
    else {
      result = largest(c.width, c.height, padding, bg.originalWidth, bg.originalHeight);
    }

    let location = {
      x: (w - result.width) / 2,
      y: (h - result.height) / 2
    };

    let bginfotextoffSetX = (bgInfoText.current as Konva.Node).getSize().width / 2;
    let bginfotextoffSetY = (bgInfoText.current as Konva.Node).getSize().height / 2;
    setBgRectTextOffset({ x: bginfotextoffSetX, y: bginfotextoffSetY });
    setL(result);
    setBgLoc(location);
  }, [size, stageRef, scene, getBackgroundAsset]);

  const updateSceneAsset = (ctx: any) => {
    let sceneAssets = Object.assign([], scene.assets).map((a) => {
      if (ctx.sceneId === a.sceneId) {
        a.x = ctx.x;
        a.y = ctx.y;
        
        if(ctx.width)
          a.width = ctx.width;

        if(ctx.height)
          a.height = ctx.height;
      }
      return a;
    });
    setScene({ assets: sceneAssets, dirty: true });
  };

  const determineOptionsVisibility = () => {
    let params = queryString.parse(location.search);
    let hasEvent = (params.event) ? true : false;
    let hasScene = (params.scene) ? true : false;
    let showAllAssets = (hasEvent && hasScene);
    let showSceneAssets = (hasEvent && hasScene);

    let visibility = {
      events: true,
      scenes: true,
      background: true,
      allAssets: showAllAssets,
      sceneAssets: showSceneAssets
    };

    setOptionsVisibility(visibility);
  };

  useEffect(() => {
    determineOptionsVisibility();
  }, [location.search]);

  const bgRectOnClick = () => {
    subject.next({ name: "openBgModal" });
  }

  const bgRectOnMouseEnter = () => {
    document.body.style.cursor = "pointer";
  }

  const bgRectOnMouseLeave = () => {
    document.body.style.cursor = "default";
  }

  const bgTextOnMouseEnter = () => {
    document.body.style.cursor = "pointer";
  }

  const bgTextOnMouseLeave = () => {
    document.body.style.cursor = "default";
  }

  const bgTextOnClick = () => {
    subject.next({ name: "openBgModal" });
  }

  const bgRectIsVisible = () => {
    
    let params = queryString.parse(location.search);
    let hasEvent = (params.event) ? true : false;
    let hasScene = (params.scene) ? true : false;

    return getBackgroundAsset() === null && hasEvent && hasScene;
  }

  return <React.Fragment>
    <MainMenu pageName="design" />
    <div className="panel right-column">
      <Options visibility={optionsVisibility} subject={subject} scene={scene} assets={assets} />
    </div>
    <div ref={assetFrameWrapper} className="asset-frame-wrapper">
      <Stage ref={stageRef} width={0} height={0} onMouseDown={e => {
        const deselect = e.target.attrs.name === "background" || e.target === e.target.getStage();
        if (deselect) {
          selectImage(null);
        }
      }}>
        <Layer ref={layerRef}>
          {(optionsVisibility as any).background}
          <Rect visible={bgRectIsVisible()} onMouseEnter={bgRectOnMouseEnter} onMouseLeave={bgRectOnMouseLeave} onDblClick={bgRectOnClick} x={bgLoc.x} y={bgLoc.y} width={l.width} height={l.height} fill={"#f5f5f5"} cornerRadius={4} shadowColor={"#cccccc"} shadowBlur={5} />
          <Text visible={bgRectIsVisible()} onMouseEnter={bgTextOnMouseEnter} onMouseLeave={bgTextOnMouseLeave} onDblClick={bgTextOnClick} fontSize={25} opacity={0.20} align="center" width={300} x={bgLoc.x + (l.width / 2)} y={bgLoc.y + (l.height / 2)} ref={bgInfoText} offset={bgRectTextOffset} text="Double-Click to Select Background" />
          <Group scale={{ x: l.scale.x, y: l.scale.y }}
            ref={bgRef}
            x={bgLoc.x}
            y={bgLoc.y}>
            <Background options={{}} asset={getBackgroundAsset()} />
            {scene.assets.map((a, idx) => {
              return <Asset subject={subject} onChange={updateSceneAsset}
                onSelect={() => {
                  selectImage(a.sceneId);
                }}
                imageProps={{}}
                isSelected={a.sceneId === selectedImageSceneId}
                key={idx}
                asset={Object.assign({}, a)} />;
            })
            }
          </Group>
        </Layer>
      </Stage>
      <SelectBackgroundModal subject={subject} assets={assets} />
    </div>
  </React.Fragment>;
}

function useWindowSize() {
  const isClient = typeof window === 'object';

  const getSize = useCallback(() => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }, [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return () => { };
    }
    function handleResize() {
      setWindowSize(getSize());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);

  }, [getSize, isClient]);
  return windowSize;
}

export default App;