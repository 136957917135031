import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from "react-router-dom";
import axios from 'axios';
import queryString from 'query-string';
import './index.scss';
import { Message, Header } from 'semantic-ui-react';
import Select from 'react-select';

function Event({ visible, subject }) {
    const { company } = useParams();
    const location = useLocation();
    const history = useHistory();
    
    const [events, setEvents] = useState([]);

    useEffect(() => {
        let url = `${process.env.REACT_APP_FANDANGLE_API_BASE_URL}/api/${process.env.REACT_APP_FANDANGLE_API_VERSION}/events/company/${company}`;

        axios.get(url).then((response) => {
            if (response.data && response.data.length > 0) {
                subject.next({ name: `loadAssetsForCompany`, data: {} });
                setEvents(response.data);
            }
        });
    }, [company]);

    if (visible === false) {
        return <React.Fragment></React.Fragment>;
    }

    const getOptions = () => {
        let options = [ { label: "Select an Event", value: null } ];
        options = options.concat(events.map(e => { return { value: e.eventUUID, label: e.eventName }; }));
        return options;
    };

    const getValue = () => {
        let params = queryString.parse(location.search);
        let v = getOptions().filter(o => { return o.value === params.event; })[0];
        if(v)
            subject.next({name:"eventChanged", data: { eventUUID: v.value }});
        else
        subject.next({name:"eventChanged", data: { eventUUID: null }});
        
        return v;
    }
    const onChange = (e) => {
        const currentLocation = Object.assign({}, location);
        let params = queryString.parse(location.search);
        params.event = e.value;
        delete params.scene;
        if(!e.value)
        {
            delete params.event;
        }

        currentLocation.search = queryString.stringify(params);
        history.push(currentLocation);

        subject.next({name:"eventChanged", data: { eventUUID: e.value }});
    };

    if (events.length < 1) {
        return <React.Fragment><Header dividing as='h4'>Event</Header> <Message info size="mini">There are no events available</Message></React.Fragment>;
    }

    return <div>
        <Header dividing as='h4'>Event</Header>
        <Select defaultValue={getOptions()[0]} value={getValue()} onChange={onChange} options={getOptions()} />
    </div>;
}

export default Event;