import React from 'react';
import './index.scss';
import { List, Message, Header } from 'semantic-ui-react';
import AllAssetsModal from './all-assets-modal';
function AllAssets({ visible, subject, assets }) {
    if(visible === false)
    {
        return <React.Fragment></React.Fragment>;
    }

    const renderAssetLineItems = () => {
        return (assets.map((a: any) => {
            return <List.Item key={a.assetUUID}>
                <List.Icon className={"clickable"} title={"Add to scene"} size="large" color="green" name='add square' onClick={(event) => { subject.next({ name: `addAssetToScene`, data: { asset: a } }); }} data-assetuuid={a.assetUUID} />
                {/* <List.Icon className={"clickable"} size="large" color="red" name='delete' onClick={(event) => { props.subject.next({ name: `removeAsset`, data: { asset: a } }); }} /> */}
                {/* <List.Content>{a.filename.replace(a.extension, "")}</List.Content> */}
                <AllAssetsModal subject={ subject } asset={a} />
            </List.Item>
        }));
    }

    const header = (<Header dividing as="h4">Available Assets</Header>);

    if (assets && assets.length > 0) {
        return <React.Fragment>
        {header}
        <div style={{ maxHeight: '126px', minHeight: '126px', overflowY: "auto" }}>
            <div style={{ marginBottom: '5px' }}>
                <List>
                    {renderAssetLineItems()}
                </List>
            </div>
        </div></React.Fragment>;
    }
    
    return <React.Fragment>{header}<Message hidden={assets && assets.length > 0} info size='mini'>There are no assets available.</Message></React.Fragment>;
}

export default AllAssets;